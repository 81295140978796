var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__container_main"},[(!!_vm.content.length)?[(_vm.hasTooltip)?_c('div',{staticClass:"table__container"},[_c('ul',{staticClass:"table__header",style:(_vm.columnsSizeStyle)},_vm._l((_vm.columns.concat( [{ extra: _vm.hasTooltip }])),function(headerItem){return _c('li',{key:headerItem.name,style:(headerItem.name === 'name' ? 'justify-content: flex-start' : '')},[(headerItem.isSortable)?_c('sort-table',{attrs:{"item":headerItem.name},on:{"sort":_vm.sortHandler}},[_c('div',{staticClass:"table__content-item"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")])]):(headerItem.extra)?_c('div',{staticClass:"extra-container"}):_c('div',[_vm._v(_vm._s(_vm.$t(("table." + (headerItem.name)))))])],1)}),0),_c('ul',{staticClass:"table__content"},_vm._l((_vm.content),function(item){return _c('li',{key:item.uuid,staticClass:"table table__report items__container",class:{
            disabled: 'is_active' in item ? !item.is_active : false,
            blocked: 'disabled' in item ? item.disabled : false,
            draft: item.filing_status === 'draft',
            expired: 'data' in item ? (item.data.financial_support_guarantee_expired === true) : false,
          },style:(_vm.columnsSizeStyle),on:{"click":function($event){return _vm.openCardItem(item)}}},_vm._l((_vm.columns.concat( [{ extra: _vm.hasTooltip }])),function(headerItem){return _c('div',{key:headerItem.name,staticClass:"table_items-content",class:{
              item__center: headerItem.alignCenter,
              table__content: !headerItem.extra,
              item__left: headerItem.name === 'name',
            }},[_vm._v(" "+_vm._s(!item[headerItem.name] && !headerItem.extra ? '-' : '')+" "),(headerItem.name === 'review_status')?[_c('Tooltip',{attrs:{"text":_vm.$t(("table." + (_vm.iconStatus(item)))),"position":"top","is-dynamic":true}},[_c('svg-icon',{staticClass:"table__status-icon",attrs:{"name":("" + (_vm.iconStatus(item)))}})],1)]:(headerItem.name === 'star_category')?[(item.type_en === 'hotel')?_c('star-rating',{attrs:{"active-on-click":false,"star-size":12,"fixed-points":1,"show-rating":false,"clearable":false,"padding":4,"border-width":2,"inline":true,"read-only":true,"border-color":"#6B6B6B","active-border-color":"#FFB33B","active-color":"#FFB33B","inactive-color":"#fff"},model:{value:(item[headerItem.name]),callback:function ($$v) {_vm.$set(item, headerItem.name, $$v)},expression:"item[headerItem.name]"}}):_vm._e()]:(
                headerItem.name === 'name' &&
                item[headerItem.name].length >= _vm.titleMaxLength
              )?[_c('Tooltip',{attrs:{"text":item[headerItem.name],"position":"bottom","is-dynamic":true,"isTable":""}},[_c('p',{staticClass:"extra__title"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")]),_c('div',{staticClass:"table__title"},[_vm._v(" "+_vm._s(item[headerItem.name].slice(0, _vm.titleMaxLength) + '...')+" ")])])]:(headerItem.type === 'date')?[_c('TableDate',{attrs:{"date":item[headerItem.name]}})]:(!headerItem.extra)?[_c('p',{staticClass:"extra__title"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")]),_vm._v(" "+_vm._s(item[headerItem.name])+" ")]:_vm._e(),(headerItem.extra)?_c('TableRowMenuItem',{key:item.uuid,attrs:{"item":item,"search-results":_vm.searchResults},on:{"active-menu":_vm.openMenu}}):_vm._e()],2)}),0)}),0),(_vm.getPageQty > 1)?_c('Pagination',{on:{"detectedPageValue":_vm.detectedPageValue}}):_vm._e()],1):_c('div',{staticClass:"table__container"},[_c('ul',{staticClass:"table__header",style:(_vm.columnsSizeStyle)},_vm._l((_vm.columns.concat( [{ extra: _vm.hasTooltip }])),function(headerItem){return _c('li',{key:headerItem.name},[(headerItem.isSortable)?_c('sort-table',{attrs:{"item":headerItem.name},on:{"sort":_vm.sortHandler}},[_c('div',{staticClass:"table__content-item"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")])]):(headerItem.extra)?_c('div',{staticClass:"extra-container"}):_c('div',[_vm._v(_vm._s(_vm.$t(("table." + (headerItem.name)))))])],1)}),0),_c('ul',{staticClass:"table__content"},_vm._l((_vm.content),function(item){return _c('li',{key:item.id,staticClass:"table table__report items__container",class:{
            disabled: 'is_active' in item ? !item.is_active : false,
            blocked: 'disabled' in item ? item.disabled : false,
            draft: item.filing_status === 'draft',
          },style:(_vm.columnsSizeStyle),on:{"click":function($event){return _vm.openCardItem(item)}}},_vm._l(([].concat( _vm.columns )),function(headerItem){return _c('div',{key:headerItem.name,staticClass:"table_items-content",class:{ item__center: headerItem.alignCenter }},[_vm._v(" "+_vm._s(!item[headerItem.name] && !headerItem.extra ? '-' : '')+" "),(headerItem.name === 'review_status')?[_c('Tooltip',{attrs:{"text":_vm.$t(("table." + (_vm.iconStatus(item)))),"position":"top","is-dynamic":true}},[_c('svg-icon',{staticClass:"table__status-icon",attrs:{"name":("" + (_vm.iconStatus(item)))}})],1)]:(
                headerItem.name === 'name' &&
                item[headerItem.name].length >= _vm.titleMaxLength
              )?[_c('Tooltip',{attrs:{"text":item[headerItem.name],"position":"bottom","is-dynamic":true,"isTable":""}},[_c('p',{staticClass:"extra__title"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")]),_c('div',{staticClass:"table__title"},[_vm._v(" "+_vm._s(item[headerItem.name].slice(0, _vm.titleMaxLength) + '...')+" ")])])]:(headerItem.type === 'date')?[_c('TableDate',{attrs:{"date":item[headerItem.name]}})]:(!headerItem.extra)?[_c('p',{staticClass:"extra__title"},[_vm._v(" "+_vm._s(_vm.$t(("table." + (headerItem.name))))+" ")]),_vm._v(" "+_vm._s(item[headerItem.name])+" ")]:_vm._e(),(headerItem.extra)?_c('TableRowMenuItem',{key:item.uuid,attrs:{"item":item,"search-results":_vm.searchResults},on:{"active-menu":_vm.openMenu}}):_vm._e()],2)}),0)}),0),(_vm.getPageQty > 1)?_c('Pagination',{on:{"detectedPageValue":_vm.detectedPageValue}}):_vm._e()],1)]:_c('div',{staticClass:"table__no-data"},[_vm._v(_vm._s(_vm.$t('table.no_data')))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }