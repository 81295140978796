<template>
  <div class="table__container_main">
    <template v-if="!!content.length">
      <div v-if="hasTooltip" class="table__container">
        <ul :style="columnsSizeStyle" class="table__header">
          <li
            v-for="headerItem in [...columns, { extra: hasTooltip }]"
            :key="headerItem.name"
            :style="
              headerItem.name === 'name' ? 'justify-content: flex-start' : ''
            "
          >
            <sort-table
              v-if="headerItem.isSortable"
              :item="headerItem.name"
              @sort="sortHandler"
            >
              <div class="table__content-item">
                {{ $t(`table.${headerItem.name}`) }}
              </div>
            </sort-table>
            <div v-else-if="headerItem.extra" class="extra-container"></div>
            <div v-else>{{ $t(`table.${headerItem.name}`) }}</div>
          </li>
        </ul>
        <ul class="table__content">
          <li
            v-for="item in content"
            :key="item.uuid"
            :style="columnsSizeStyle"
            class="table table__report items__container"
            :class="{
              disabled: 'is_active' in item ? !item.is_active : false,
              blocked: 'disabled' in item ? item.disabled : false,
              draft: item.filing_status === 'draft',
              expired: 'data' in item ? (item.data.financial_support_guarantee_expired === true) : false,
            }"
            @click="openCardItem(item)"
          >
            <div
              v-for="headerItem in [...columns, { extra: hasTooltip }]"
              :key="headerItem.name"
              class="table_items-content"
              :class="{
                item__center: headerItem.alignCenter,
                table__content: !headerItem.extra,
                item__left: headerItem.name === 'name',
              }"
            >
              {{ !item[headerItem.name] && !headerItem.extra ? '-' : '' }}

              <template v-if="headerItem.name === 'review_status'">
                <Tooltip
                  :text="$t(`table.${iconStatus(item)}`)"
                  position="top"
                  :is-dynamic="true"
                >
                  <svg-icon
                    :name="`${iconStatus(item)}`"
                    class="table__status-icon"
                  />
                </Tooltip>
              </template>
              <template v-else-if="headerItem.name === 'star_category'">
                <star-rating
                  v-if="item.type_en === 'hotel'"
                  v-model="item[headerItem.name]"
                  :active-on-click="false"
                  :star-size="12"
                  :fixed-points="1"
                  :show-rating="false"
                  :clearable="false"
                  :padding="4"
                  :border-width="2"
                  :inline="true"
                  :read-only="true"
                  border-color="#6B6B6B"
                  active-border-color="#FFB33B"
                  active-color="#FFB33B"
                  inactive-color="#fff"
                />
              </template>
              <template
                v-else-if="
                  headerItem.name === 'name' &&
                  item[headerItem.name].length >= titleMaxLength
                "
              >
                <Tooltip
                  :text="item[headerItem.name]"
                  position="bottom"
                  :is-dynamic="true"
                  isTable
                >
                  <p class="extra__title">
                    {{ $t(`table.${headerItem.name}`) }}
                  </p>
                  <div class="table__title">
                    {{ item[headerItem.name].slice(0, titleMaxLength) + '...' }}
                  </div>
                </Tooltip>
              </template>
              <template v-else-if="headerItem.type === 'date'">
                <TableDate :date="item[headerItem.name]" />
              </template>
              <template v-else-if="!headerItem.extra">
                <p class="extra__title">
                  {{ $t(`table.${headerItem.name}`) }}
                </p>
                {{ item[headerItem.name] }}
              </template>
              <TableRowMenuItem
                v-if="headerItem.extra"
                :key="item.uuid"
                :item="item"
                :search-results="searchResults"
                @active-menu="openMenu"
              />

            </div>
          </li>
        </ul>
        <Pagination
          v-if="getPageQty > 1"
          @detectedPageValue="detectedPageValue"
        />
      </div>
      <div v-else class="table__container">
        <ul :style="columnsSizeStyle" class="table__header">
          <li
            v-for="headerItem in [...columns, { extra: hasTooltip }]"
            :key="headerItem.name"
          >
            <sort-table
              v-if="headerItem.isSortable"
              :item="headerItem.name"
              @sort="sortHandler"
            >
              <div class="table__content-item">
                {{ $t(`table.${headerItem.name}`) }}
              </div>
            </sort-table>
            <div v-else-if="headerItem.extra" class="extra-container"></div>
            <div v-else>{{ $t(`table.${headerItem.name}`) }}</div>
          </li>
        </ul>
        <ul class="table__content">
          <li
            v-for="item in content"
            :key="item.id"
            :style="columnsSizeStyle"
            class="table table__report items__container"
            :class="{
              disabled: 'is_active' in item ? !item.is_active : false,
              blocked: 'disabled' in item ? item.disabled : false,
              draft: item.filing_status === 'draft',
            }"
            @click="openCardItem(item)"
          >
            <div
              v-for="headerItem in [...columns]"
              :key="headerItem.name"
              class="table_items-content"
              :class="{ item__center: headerItem.alignCenter }"
            >
              {{ !item[headerItem.name] && !headerItem.extra ? '-' : '' }}
              <template v-if="headerItem.name === 'review_status'">
                <Tooltip
                  :text="$t(`table.${iconStatus(item)}`)"
                  position="top"
                  :is-dynamic="true"
                >
                  <svg-icon
                    :name="`${iconStatus(item)}`"
                    class="table__status-icon"
                  />
                </Tooltip>
              </template>
              <template
                v-else-if="
                  headerItem.name === 'name' &&
                  item[headerItem.name].length >= titleMaxLength
                "
              >
                <Tooltip
                  :text="item[headerItem.name]"
                  position="bottom"
                  :is-dynamic="true"
                  isTable
                >
                  <p class="extra__title">
                    {{ $t(`table.${headerItem.name}`) }}
                  </p>
                  <div class="table__title">
                    {{ item[headerItem.name].slice(0, titleMaxLength) + '...' }}
                  </div>
                </Tooltip>
              </template>
              <template v-else-if="headerItem.type === 'date'">
                <TableDate :date="item[headerItem.name]" />
              </template>
              <template v-else-if="!headerItem.extra">
                <p class="extra__title">
                  {{ $t(`table.${headerItem.name}`) }}
                </p>
                {{ item[headerItem.name] }}
              </template>
              <TableRowMenuItem
                v-if="headerItem.extra"
                :key="item.uuid"
                :item="item"
                :search-results="searchResults"
                @active-menu="openMenu"
              />
            </div>
          </li>
        </ul>
        <Pagination
          v-if="getPageQty > 1"
          @detectedPageValue="detectedPageValue"
        />
      </div>
    </template>
    <div class="table__no-data" v-else>{{ $t('table.no_data') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tableColumnsSize from '@/models/table_columns'
import SortTable from '@/elements/SortTable.vue'
import TableRowMenuItem from '@/elements/TableRowMenuItem.vue'
import TableDate from '@/elements/TableDate.vue'
import Pagination from './Pagination.vue'
import StarRating from 'vue-star-rating'

export default {
  components: {
    SortTable,
    TableRowMenuItem,
    Pagination,
    TableDate,
    StarRating,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      //====== ????
      type: Array,
      default: () => [],
    },
    hasTooltip: {
      type: Boolean,
      default: true,
    },
    searchResults: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortBy: '',
      selectedList: [],
      sort: {},
      isActive: false,
      selectAll: [],
      tableColumnsSize,
      titleMaxLength: 65,
    }
  },

  computed: {
    ...mapGetters([
      'getTableModel',
      'getFilterTableModel',
      'getPageQty',
      'getCurrentIconStatus',
    ]),

    currentTableModel() {
      return this.$route.name === 'search-results'
        ? this.getFilterTableModel
        : this.getTableModel
    },

    columns() {
      let curColumns = !!this.currentTableModel[this.$route.name]?.columns
        ? this.currentTableModel[this.$route.name]?.columns
        : []
      return !!this.header.length ? this.header : curColumns
    },

    columnsSizeStyle() {
      let columnsSize = 'grid-template-columns: '

      this.columns?.forEach((element) => {
        columnsSize += this.tableColumnsSize[element.name] + ' '
      })
      this.hasTooltip ? (columnsSize += '1fr') : ''
      return columnsSize
    },

    // nameAlignment() {
    //   let alignmentProperty = 'justify-items: ';
    //     this.columns?.find((item) => item.name === 'name')?.alignCenter;

    // },

    isTooltip() {
      //======== ???
      return this.tooltip === null
        ? this.currentTableModel[this.$route.name]?.tooltip
        : this.tooltip
    },
  },

  methods: {
    iconStatus(item) {
      let iconStatus = 'info_questions'
      this.getCurrentIconStatus?.forEach((element) => {
        if (
          element.filling_status == item.filling_status &&
          element.review_status == item.review_status
        ) {
          iconStatus = element.icon
        }
      })

      return iconStatus
    },

    openCardItem(item) {
      let item_action = ''
      let item_path_name = ''

      if (!!item.review_status) {
        item_action =
          this.currentTableModel[this.$route.name].actions[item.review_status]
      } else {
        if ('isExist' in item) {
          for (let key in this.currentTableModel[this.$route.name].actions) {
            if (
              this.currentTableModel[this.$route.name].actions[key] ===
              item.isExist
            ) {
              item_action = key
            }
          }
        } else {
          item_action = this.currentTableModel[this.$route.name].actions
        }
      }

      if (this.$route.name === 'search-results') {
        item_path_name = this.currentTableModel[item.type_en]
      } else {
        if ('isExist' in item) {
          item_path_name =
            this.currentTableModel[this.$route.name].path_name[item_action]
        } else {
          item_path_name = this.currentTableModel[this.$route.name].path_name
        }
      }

      let params_data = {
        id: item.uuid,
        activity: item_action,
      }

      if ('props' in item) {
        params_data = { ...params_data, ...item.props }
      }

      !!item_path_name &&
        this.$router.push({
          name: item_path_name,
          params: params_data,
        })
    },

    openMenu(value) {
      this.isActive = value
    },
    detectedPageValue() {
      this.$emit('tableHandler')
    },
    sortHandler(payload) {
      this.$store.commit('SET_TABLE_SORT_DATA', { sort: payload })
      this.$emit('tableHandler')
    },
    // detectRoute(value) {
    //   this.routeToPush = value;
    // },
  },
}
</script>

<style lang="sass" scoped>

.table

  &__content
    .vue-star-rating
      margin-bottom: 0 !important
      padding: 0 !important

    .table__report.items__container div
      padding: 0 !important

.table__header_checkbox,
.table__checkbox
  // opacity: 0
  display: none

// .table__container.table--cols_6
//   .table__header, .table__report
//     grid-template-columns: 17% 22% 28% 15% 15% 3%

// .table__container.table--cols_5
//   .table__header, .table__report
//     grid-template-columns: 30% 30% 17% 20% 3%

  .table__report > div:first-child
    max-width: 360px

// .table__container.table--cols_5.no_extra
//   .table__header, .table__report
//     grid-template-columns: 30% 10% 15% 15% 30%

.table__container.table--cols_5.no_extra
  .table__header > li:last-child, .table__report > div:last-child
    display: none

.table__container_main
  background-color: $white
  margin-bottom: 8rem

.search-result-table
  .table__header li
    justify-content: center
  .table__content li
    text-align: center

.disabled
  .table__content
    opacity: 0.5

.table__header, .table__report
  display: grid
  gap: 10px
  // grid-template-columns: 15% 19% 31% 14% 16% 5%
  align-items: center
  // justify-items: flex-start
  text-align: left
  @include s
    display: flex
    flex-wrap: wrap

.request-list__table .table__report
  margin-bottom: 50px

.table__content
  // text-align: center
  gap: 10px
  font-size: 14px

.table--admin__news  .table__content .table__report div
  padding: 20px

.table--admin__news  .table__content .table__report div:first-child
  padding: 0px

.table--admin__news  .table__content .table__report div:last-child
  padding: 0px

.table--admin__news  .table__content .table__report div:nth-child(2) div
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical

.table--admin__news .table__header li
  padding: 20px

.table--admin__news .table__header li:first-child
  padding: 0px

.table--admin__news .table__header li:last-child
  padding: 0px

.table__header_checkbox
  margin-right: 24px
  min-width: 18px

.table--profile-ulmg-admin .table__wrapper
  display: block
.table--profile-ulmg-admin  .items__container div, .table--profile-smd-admin .items__container div div
  margin-bottom: 0px
  @include s
    margin-bottom: 12px
.item__left
  justify-self: flex-start
  text-align: left
  @include s
    text-align: center
    justify-self: center
.table--profile-ulmg-admin .table__report div:first-child
  @include s
    height: 100px

.table--profile-smd-admin .table__report div:first-child
  @include xs
// height: 40px

.table__report.blocked > div:not(:first-child):not(:last-child)
  opacity: 0.5

.table__report label
  @include s
  margin-bottom: 12px

.extra-container, .header
  min-height: 47px

.items__container
  cursor: pointer
  &:hover
    &::v-deep
      .table__menu-text
        color: $black
        &::after
          content: ""
      .table__menu-ico
        fill: $black


.items__container div
  display: flex
  align-items: center
  justify-content: start
  @include s
    width: 100%
    display: block
    color: $gray_active
    position: static
// margin-bottom: 20px
// height: 20px
// margin-top: 10px
.items__container div:last-child
  @include s
    margin-bottom: 10px

.item__center
  justify-content: center !important
  transform: translateX(-25px)

  @media (max-width: 1150px)
    transform: none

// .table__report div:first-child
//   @include xs
//     height: 23px

.table__report div:last-child
  @include s
// height: 30px

.table__report .container
  height: 84px

.items__container
  min-height: 82px
  @include s
    height: auto
    position: relative

.table__report label
  margin-right: 24px

.table--profile-ulmg-admin .table__header .checkbox__form
  border: 2px solid black
  margin-right: 24px

.extra__title
  display: none
  @include s
    display: block
    font-size: 12px
    line-height: 22px
    color: $black

.table__status-icon
  height: 2rem
  width: 2rem
  @include s
    height: 30px
    width: 24px
    position: absolute
    bottom: 40px
    right: 7px

.table__header
  font-size: 14px
  font-weight: 800
  @include s
    display: none

.table__content li
  border-bottom: 2px solid $gray_border
  margin-top: 10px
  @include s
    margin-top: 0
.table__content li:last-child
  border-bottom: none

.icon-success-check,
.icon-error-check
  margin-right: 12px

.vote_status
  width: 10px
  height: 10px
  margin-right: 10px
  border-radius: 50%
  background: #6EB862

.vote_status.accept
  background: #6EB862

.vote_status.edit
  background: #FFB33B

.vote_status.cancel
  background: #ED6A5E

.vote_status.downgrade
  background: #C4C4C4

.v-pagination
  display: none !important

.table__status-icon
  margin-right: 12px

.table__header
  border-bottom: 2px solid #EDEDED
  padding-bottom: 18px
  // height: 82px

.table__header li
  align-items: center
  display: flex

.table__content-item
  padding-right: 10px

.voting-upgrade
  color: $success

.voting-declined
  color: $error

.voting-revision
  color: $warning

.voting-downgrade
  color: #C4C4C4

.justify-to-start
  margin-right: auto

.table__report.draft
  color: $gray_notification

.table__report.expired
  color: #e8262e
  opacity: 0.5

.table__no-data
  text-align: center
  padding: 60px 0
  color: #C4C4C4
  font-size: 28px

.table_items-content
  @include xs
    max-width: 80%
    // border: solid 1px red
</style>

